@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
@font-face {
  font-family: pages-icon;
  src: url(/static/media/Pages-icon095c.cd3f981d.eot);
  src: url(/static/media/Pages-icond41d.cd3f981d.eot?#iefix-u69vo5) format('embedded-opentype'),
    url(/static/media/Pages-icon095c.daae13ce.woff) format('woff'),
    url(/static/media/Pages-icon095c.a0ee0bee.ttf) format('truetype'),
    url(/static/media/Pages-icon095c.ff9a0e13.svg#Pages-icon) format('svg');
  font-weight: 400;
  font-style: normal;
}
.pg {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^='pg-'],
[class*=' pg-'] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pg-link:before {
  content: '\e900';
}
.pg-menu:before {
  content: '\e901';
}
.pg-alt_menu:before {
  content: '\e902';
}
.pg-thumbs:before {
  content: '\e903';
}
.pg-world:before {
  content: '\e904';
}
.pg-boxnet:before {
  content: '\e662';
}
.pg-signals:before {
  content: '\e61c';
}
.pg-crop:before {
  content: '\e657';
}
.pg-folder_alt:before {
  content: '\e658';
}
.pg-folder:before {
  content: '\e659';
}
.pg-theme:before {
  content: '\e65a';
}
.pg-battery_empty:before {
  content: '\e65b';
}
.pg-battery:before {
  content: '\e65c';
}
.pg-note:before {
  content: '\e65d';
}
.pg-server_hard:before {
  content: '\e65e';
}
.pg-servers:before {
  content: '\e65f';
}
.pg-menu_justify:before {
  content: '\e660';
}
.pg-credit_card:before {
  content: '\e60f';
}
.pg-fullscreen_restore:before {
  content: '\e633';
}
.pg-fullscreen:before {
  content: '\e634';
}
.pg-minus:before {
  content: '\e635';
}
.pg-minus_circle:before {
  content: '\e63a';
}
.pg-plus_circle:before {
  content: '\e656';
}
.pg-refresh_new:before {
  content: '\e600';
}
.pg-close_line:before {
  content: '\e601';
}
.pg-close:before {
  content: '\e60a';
}
.pg-arrow_down:before {
  content: '\e60b';
}
.pg-arrow_left_line_alt:before {
  content: '\e628';
}
.pg-arrow_left:before {
  content: '\e629';
}
.pg-arrow_lright_line_alt:before {
  content: '\e62a';
}
.pg-arrow_maximize_line:before {
  content: '\e62b';
}
.pg-arrow_maximize:before {
  content: '\e62c';
}
.pg-arrow_minimize_line:before {
  content: '\e62f';
}
.pg-arrow_minimize:before {
  content: '\e630';
}
.pg-arrow_right:before {
  content: '\e631';
}
.pg-arrow_up:before {
  content: '\e632';
}
.pg-more:before {
  content: '\e655';
}
.pg-bag:before {
  content: '\e602';
}
.pg-bag1:before {
  content: '\e603';
}
.pg-bold:before {
  content: '\e604';
}
.pg-calender:before {
  content: '\e605';
}
.pg-camera:before {
  content: '\e606';
}
.pg-centeralign:before {
  content: '\e607';
}
.pg-charts:before {
  content: '\e608';
}
.pg-clock:before {
  content: '\e609';
}
.pg-comment:before {
  content: '\e60c';
}
.pg-contact_book:before {
  content: '\e60d';
}
.pg-credit_card_line:before {
  content: '\e60e';
}
.pg-cupboard:before {
  content: '\e610';
}
.pg-desktop:before {
  content: '\e611';
}
.pg-download:before {
  content: '\e612';
}
.pg-eraser:before {
  content: '\e613';
}
.pg-extra:before {
  content: '\e614';
}
.pg-form:before {
  content: '\e615';
}
.pg-grid:before {
  content: '\e616';
}
.pg-home:before {
  content: '\e617';
}
.pg-image:before {
  content: '\e618';
}
.pg-inbox:before {
  content: '\e619';
}
.pg-indent:before {
  content: '\e61a';
}
.pg-italic:before {
  content: '\e61b';
}
.pg-laptop:before {
  content: '\e61d';
}
.pg-layouts:before {
  content: '\e61e';
}
.pg-layouts2:before {
  content: '\e61f';
}
.pg-layouts3:before {
  content: '\e620';
}
.pg-layouts4:before {
  content: '\e621';
}
.pg-leftalign:before {
  content: '\e622';
}
.pg-like:before {
  content: '\e623';
}
.pg-like1:before {
  content: '\e624';
}
.pg-lock:before {
  content: '\e625';
}
.pg-mail:before {
  content: '\e626';
}
.pg-map:before {
  content: '\e627';
}
.pg-menu_lv:before {
  content: '\e62d';
}
.pg-movie:before {
  content: '\e636';
}
.pg-ordered_list:before {
  content: '\e637';
  color: #231f20;
}
.pg-outdent:before {
  content: '\e638';
}
.pg-phone:before {
  content: '\e639';
}
.pg-plus:before {
  content: '\e63b';
}
.pg-power:before {
  content: '\e63c';
}
.pg-printer:before {
  content: '\e63d';
}
.pg-refresh:before {
  content: '\e63e';
}
.pg-resize:before {
  content: '\e63f';
}
.pg-right_align:before {
  content: '\e640';
}
.pg-save:before {
  content: '\e641';
}
.pg-search:before {
  content: '\e642';
}
.pg-sent:before {
  content: '\e643';
}
.pg-settings_small_1:before {
  content: '\e644';
}
.pg-settings_small:before {
  content: '\e645';
}
.pg-settings:before {
  content: '\e646';
}
.pg-shopping_cart:before {
  content: '\e647';
}
.pg-social:before {
  content: '\e648';
}
.pg-spam:before {
  content: '\e649';
}
.pg-suitcase:before {
  content: '\e64a';
}
.pg-tables:before {
  content: '\e64b';
}
.pg-tablet:before {
  content: '\e64c';
}
.pg-telephone:before {
  content: '\e64d';
}
.pg-text_style:before {
  content: '\e64e';
}
.pg-trash_line:before {
  content: '\e64f';
}
.pg-trash:before {
  content: '\e650';
}
.pg-ui:before {
  content: '\e651';
}
.pg-underline:before {
  content: '\e652';
}
.pg-unordered_list:before {
  content: '\e653';
}
.pg-video:before {
  content: '\e654';
}

body.mobile .page-sidebar .sidebar-menu .menu-items li:hover a {
  color: #788195;
}

body.mobile .page-sidebar .sidebar-menu .menu-items li.active > a,
body.mobile .page-sidebar .sidebar-menu .menu-items li.open > a {
  color: #000;
}

.page-sidebar {
  width: 260px;
  background-color: #fff;
  z-index: 1000;
  left: 0;
  position: fixed;
  bottom: 0;
  top: 0;
  right: auto;
  overflow: hidden;
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

.page-sidebar a,
.page-sidebar button {
  color: #788195;
}

.page-sidebar a:hover,
.page-sidebar button:hover,
.page-sidebar a:active,
.page-sidebar button:active {
  color: #000;
  background-color: transparent !important;
}

.page-sidebar a:hover:focus,
.page-sidebar button:hover:focus,
.page-sidebar a:active:focus,
.page-sidebar button:active:focus {
  color: #000;
}

.page-sidebar a:visited,
.page-sidebar button:visited,
.page-sidebar a:focus,
.page-sidebar button:focus {
  color: #788195;
}

.page-sidebar button.sidebar-slide-toggle.active {
  color: #788195;
}

.page-sidebar button.sidebar-slide-toggle.active:focus {
  background: 0 0;
}

.page-sidebar .sidebar-header {
  display: block;
  height: 62px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  color: #232730;
  width: 100%;
  padding: 0 20px;
  padding-left: 20px;
  padding-top: 10px;
  clear: both;
  z-index: 10;
  position: relative;
}

.page-sidebar .sidebar-header .sidebar-header-controls {
  display: inline-block;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
}

.page-sidebar .sidebar-header .sidebar-slide-toggle i {
  transition: all 0.12s ease;
}

.page-sidebar .sidebar-header .sidebar-slide-toggle.active i {
  transform: rotate(-180deg);
}

.page-sidebar .close-sidebar {
  position: absolute;
  right: 19px;
  top: 14px;
  padding: 9px;
  z-index: 1;
}

.page-sidebar .close-sidebar > i {
  color: rgba(255, 255, 255, 0.57);
}

.page-sidebar .sidebar-overlay-slide {
  width: 100%;
  height: 100%;
  background-color: #272b35;
  display: block;
  z-index: 9;
  padding: 80px 20px 20px;
}

.page-sidebar .sidebar-overlay-slide.from-top {
  top: -100%;
  position: absolute;
  transition: all 0.2s ease;
}

.page-sidebar .sidebar-overlay-slide.from-top.show {
  transform: translate(0, 100%);
}

.page-sidebar .sidebar-menu {
  height: calc(100% - 50px);
  position: relative;
  width: 100%;
  border-right: 1px solid #f5f7f9;
}

.page-sidebar .sidebar-menu .outer-tab-nav-section {
  display: inline-block;
  width: 45px;
  position: absolute;
  height: 100%;
  background-color: #0aa699 !important;
}

.page-sidebar .sidebar-menu .menu-items {
  list-style: none;
  margin: 0;
  padding: 30px 0;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 10px);
  width: 100%;
}

.page-sidebar .sidebar-menu .menu-items::-webkit-scrollbar {
  background: lightyellow;
  width: 7px;
}
.page-sidebar .sidebar-menu .menu-items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.page-sidebar .sidebar-menu .menu-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.page-sidebar .sidebar-menu .menu-items li:hover > a,
.page-sidebar .sidebar-menu .menu-items li.open > a,
.page-sidebar .sidebar-menu .menu-items li.active > a,
.page-sidebar .sidebar-menu .menu-items li:hover > span,
.page-sidebar .sidebar-menu .menu-items li.open > span,
.page-sidebar .sidebar-menu .menu-items li.active > span {
  color: #000;
}

.page-sidebar .sidebar-menu .menu-items li > span,
.page-sidebar .sidebar-menu .menu-items li > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 77%;
  text-decoration: none;
  color: #788195;
}

.page-sidebar .sidebar-menu .menu-items > li > span,
.page-sidebar .sidebar-menu .menu-items > li > a {
  font-family: Arial, sans-serif;
  display: inline-block;
  padding-left: 10px;
  min-height: 40px;
  line-height: 40px;
  font-size: 14px;
  clear: both;
}

.page-sidebar .sidebar-menu .menu-items > li {
  position: relative;
  display: flex;
  padding: 5px 10px 5px 20px;
  align-items: center;
}
.page-sidebar .sidebar-menu .menu-items > li.active,
.page-sidebar .sidebar-menu .menu-items > li:hover {
  background-color: #f5f7f9;
}

.page-sidebar .sidebar-menu .menu-items > li.active:before,
.page-sidebar .sidebar-menu .menu-items > li:hover:before {
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: #017d34;
  content: '';
  left: 0;
}

.page-sidebar .sidebar-menu .menu-items > li .icon {
  width: 20px;
  height: auto;
}

.page-sidebar .sidebar-menu .menu-items > li > svg.open {
  transform: rotate(-90deg);
}

.page-sidebar .sidebar-menu .menu-items > li > span > .title,
.page-sidebar .sidebar-menu .menu-items > li > a > .title {
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 65%;
}

.page-sidebar .sidebar-menu .menu-items > li.active > ul.sub-menu {
  display: block;
}

.page-sidebar .sidebar-menu ul.sub-menu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-sidebar .sidebar-menu ul.sub-menu > li {
  background: 0 0;
  padding: 10px 0 10px 45px;
  margin-top: 1px;
}

.page-sidebar .sidebar-menu ul.sub-menu > li.active a {
  font-weight: bold;
}

.page-sidebar .sidebar-menu .muted {
  color: #576775;
  opacity: 0.45;
}

/* 
@media only screen and (max-width:1400px) {
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    bottom: 50px
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-widgets {
    display: none
  }
}

@media only screen and (min-width:980px) {
  body.ie9.menu-pin .page-sidebar {
    transform: none!important;
    -webkit-transform: none!important;
    -ms-transform: none!important
  }
  body.menu-pin [data-toggle-pin=sidebar]>i:before {
    content: "\f192"
  }
  body.menu-pin .page-sidebar {
    transform: translate(210px, 0)!important;
    -webkit-transform: translate(210px, 0)!important;
    -ms-transform: translate(210px, 0)!important
  }
  body.menu-pin .page-sidebar {
    width: 250px
  }
  body.menu-pin .page-sidebar .sidebar-header .sidebar-header-controls {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px)
  }
  body.menu-behind .page-sidebar {
    z-index: 799
  }
  body.box-layout .page-sidebar {
    left: auto;
    transform: none!important;
    -webkit-transform: none!important
  }
}

@media(max-width:991px) {
  .page-sidebar {
    width: 250px;
    z-index: auto;
    left: 0!important;
    -webkit-transform: translate3d(0, 0px, 0px)!important;
    transform: translate3d(0, 0px, 0px)!important
  }
  .page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li {
    padding: 0 30px 0 36px
  }
  .page-sidebar {
    display: none;
    z-index: auto
  }
  .page-sidebar.visible {
    display: block
  }
  .page-sidebar .page-sidebar-inner {
    z-index: 1;
    left: 0!important;
    width: 260px
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    top: 40px;
    bottom: 0
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu ul>li>a>.badge {
    display: inline-block
  }
}

@media(min-width:768px) and (max-width:1024px) and (orientation:landscape) {
  .page-sidebar .sidebar-menu .menu-items ul {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000
  }
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) {
  .page-sidebar .sidebar-menu .menu-items>li>a {
    padding-left: 27px
  }
  .page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li {
    padding: 0 27px 0 31px
  }
}

@media(max-width:767px) {
  .page-sidebar .sidebar-header {
    padding: 0 12px
  }
  .page-sidebar .sidebar-menu .menu-items>li>a {
    padding-left: 20px
  }
  .page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li {
    padding: 0 25px 0 28px
  }
}

@media(max-width:480px) {
  body .header .header-inner .toggle-secondary-sidebar {
    font-size: 16px;
    top: 12px
  }
  .page-sidebar .sidebar-header {
    height: 48px;
    line-height: 48px
  }
} */

/* latin-ext */
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font.dcab5374.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font.dcab5374.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/font.dcab5374.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/font.dcab5374.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
  color: #393939;
  background-color: #fff;
  font-family: 'Avenir', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-sz-22 {
  font-size: 22px !important;
}

.no-scroll {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
header,
section {
  margin: 0;
}

button,
input {
  border: none;
  outline: none;
}

p,
textarea {
  white-space: pre-line;
}

section {
  width: 100%;
  margin: 0;
}

.p-r {
  position: relative !important;
}
.pd-l-auto {
  padding-left: auto !important;
}
.pd-r-0 {
  padding-right: 0px !important;
}
.pd-x-7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}
.pd-x-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.pd-y-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.pd-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.pd-l-7 {
  padding-left: 7px !important;
}
.pd-5 {
  padding: 5px !important;
}
.pd-7 {
  padding: 7px !important;
}
.pd-10 {
  padding: 10px !important;
}
.pd-b-5 {
  padding-bottom: 5px !important;
}
.pd-b-0 {
  padding-bottom: 0px !important;
}
.no-padding {
  padding: 0 !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-10-20 {
  padding: 10px 20px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-t-23 {
  padding-top: 23px !important;
}

.no-margin {
  margin: 0 !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my--10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mx--10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-l-50 {
  margin-left: 50px !important;
}
.m-l-60 {
  margin-left: 60px !important;
}
.m-l-auto {
  margin-left: auto !important;
}
.m-r-auto {
  margin-right: auto !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-50 {
  margin-right: 50px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t--20 {
  margin-top: -20px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-auto {
  margin-top: auto !important;
}
.m-b-auto {
  margin-bottom: auto !important;
}
.m-b-3 {
  margin-bottom: 3px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}

.no-border {
  border: none !important;
}
.no-wrap {
  flex-wrap: nowrap !important;
}
.wrap {
  flex-wrap: wrap !important;
}
.flex {
  display: flex !important;
}
.flex-grow-100 {
  flex-grow: 1 !important;
}
.flex-h-center {
  justify-content: center !important;
}
.flex-v-center {
  align-items: center !important;
}
.flex-direction-column {
  flex-direction: column !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.pointer {
  cursor: pointer !important;
}
.underline {
  text-decoration: underline !important;
}
.center-text {
  text-align: center !important;
}
.right-text {
  text-align: right !important;
}
.left-text {
  text-align: left !important;
}
.space-between {
  justify-content: space-between !important;
}
.space-around {
  justify-content: space-around !important;
}
.float-right {
  float: right !important;
}
.inline-block {
  display: inline-block !important;
}
.bold-text {
  font-weight: bold !important;
}
.bolder-text {
  font-weight: 900 !important;
}
.border-bottom-gray-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-top-green-4 {
  border-top: 4px solid #1c811c !important;
}
.border-bottom-green-4 {
  border-bottom: 4px solid #1c811c !important;
}
.max-w-400 {
  max-width: 400px !important;
}
.max-w-700 {
  max-width: 700px !important;
}
.col-a-8 {
  width: 66.66%;
}
.col-a-7 {
  width: 58.33%;
}
.col-a-5 {
  width: 41.65%;
}

.col-a-4 {
  width: 16.66%;
}
.col-a-6 {
  width: 50%;
}

.col-1,
.col-2,
.col-2-4,
.col-3,
.col-4,
.col-5,
.col-6,
.col-half,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-2-4 {
  width: 20%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.65%;
}
.col-6,
.col-half {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.63%;
}
.col-12,
.w-100 {
  width: 100% !important;
  position: relative;
}
.w-98 {
  width: 98% !important;
}
.w-80 {
  width: 80% !important;
}

.w-10 {
  width: 25%;
  padding-left: 100px;
}

.w-45 {
  width: 45% !important;
}
.w-35 {
  width: 35% !important;
}
.w-48 {
  width: 48% !important;
}
.w-49 {
  width: 49% !important;
}

.w-50 {
  width: 50% !important;
}

.h-100 {
  height: 100% !important;
}

.h-97 {
  height: 97% !important;
}

.h-95 {
  height: 95% !important;
}
.bt1 {
  text-align: center;
  display: flex;
  justify-content: center;
}

.font-size-1 {
  font-size: 1rem !important;
}

.bt2 {
  flex: 1 1;
}
.w1 {
  width: 5%;
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}

.row2 {
  display: flex;
  flex-wrap: wrap;
  width: calc(130% + 20px);
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}

.content {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
}

footer {
  position: relative;
  background-color: #454545;
  color: #ddd;
  height: 58px;
}

div.banner {
  width: 100%;
  padding: 130px 0 100px;
  background-size: cover;
  background-blend-mode: luminosity;
  background-image: url(/static/media/banner.3ed1fa96.png);
  background-color: #1c811c55;
  background-position-y: 20%;
}

div.banner .login {
  margin: 0 auto;
  max-width: 600px;
  padding: 40px;
  position: unset;
  border-radius: 10px;
  background-color: #fffffff0;
}
div.login {
  display: flex;
}
div.login .coat-of-arm {
  border-right: 1px solid #c6c6c6;
  padding-top: 15px;
  padding-right: 40px;
  width: 270px;
}
div.login .coat-of-arm img {
  width: 140px;
  height: auto;
}
div.login .coat-of-arm h2 {
  font-size: 1.58rem;
  font-family: sans-serif;
  line-height: 1.5rem;
  font-weight: 700;
}
div.login .coat-of-arm h4 {
  font-size: 1.25rem;
  font-weight: 400;
}
div.login .form {
  padding-left: 40px;
  width: 100%;
  text-align: center;
}
.login-form-group {
  width: 100%;
  margin-bottom: 20px;
}
.login-form-group {
  width: 100%;
  margin-bottom: 15px;
}

.login-form-group input {
  width: 100%;
  padding: 13px;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.9rem;
  color: #777;
}
div.login .form button {
  width: 100%;
  color: #fff;
  padding: 13px;
  background-color: #017d34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}
div.login .form button:hover {
  background-color: #017d3488;
}
.intro {
  max-width: 800px;
  margin: 30px auto 10px;
}
div.dept-news {
  background-color: #ffffff;
  box-shadow: 5px 3px 22px #958e8e29;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
div.dept-news h4 {
  background-color: #1c811c;
  color: #ffffff;
  padding: 5px 10px 2px;
  display: inline-block;
  position: relative;
  right: 20px;
}

.headline {
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
  padding-bottom: 20px;
}
.headline:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.headline .date {
  background-color: #1c811c1f;
  padding: 5px 10px;
  text-align: center;
}
.headline .date .m {
  font-size: 0.7rem;
}
.headline .date .d {
  font-size: 1.4rem;
}
.headline .title {
  margin-left: 10px;
}
div.bottom {
  border-left: 1px solid #c6c6c6;
  padding: 0 20px;
}
div.bottom h2 {
  color: #1c811c;
  font-family: sans-serif;
  margin-bottom: 20px;
}
div.bottom ol {
  padding-left: 20px;
}
div.bottom ul {
  padding: 0;
  list-style: none;
}

@media only screen and (max-width: 900px) {
  .col-8,
  .col-7,
  .col-6 {
    width: 100%;
    flex: 1 0 100%;
  }
  .col-5,
  .col-4,
  .col-3 {
    width: 50%;
    flex: 1 0 50%;
  }
}

@media only screen and (max-width: 600px) {
  .col-5,
  .col-4,
  .col-3 {
    width: 100%;
    flex: 1 0 100%;
  }
  div.banner {
    padding-top: 60px;
  }
  div.login {
    display: block;
    margin: 20px !important;
  }
  div.login .coat-of-arm {
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  div.login .form {
    padding-left: 0;
  }
}

.select-colored select {
  background-color: #1c811c;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 3px;
  outline: none;
}

.accordion-btn:hover {
  background-color: #ddd;
  color: #777;
  transition: all 0.6s ease-in-out;
}

/* Modal Styling */
.bg-modal {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #1c811c66;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 1000;
}

.modal-content {
  width: 580px;
  height: 350px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 6px #0000000a;
  text-align: center;
  padding: 10px;
}

/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 0;
  /* margin-left: -60px; */
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* End ToolTip style */

strong {
  font-weight: 900 !important;
}

.input-group-append .btn {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
}

.input-group-append .btn:hover {
  box-shadow: 0 0.125rem 0.3rem 0 rgba(58, 59, 69, 0.4) !important;
}

.form-control:focus {
  border: 1px solid #6777ef;
}

.input-group-append span {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.input-group-prepend span {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.table-pagination-btn {
  background-color: #4caf50 /* Green */;
  border: none;
  color: white;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50%;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  border-radius: 77px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.file-preview {
  margin: 0 10px;
}

.bg-image {
  width: 100%;
  height: 200px;
  background: url(/static/media/toyota.91186154.png) no-repeat center;
  background-size: 400px;
}
.show {
  display: block !important;
}
.text-whitish {
  color: #fddfef !important;
}
.light-green {
  background-color: #25c25f !important;
}
.light-green:hover {
  background-color: #1c811c88 !important;
  transition: all 0.6s ease-in-out;
}
.light-pink {
  background-color: #f52d7f !important;
  border-radius: 7px;
  transition: all 0.6s ease-in-out;
}
.light-pink:hover {
  background-color: #f52d7fac !important;
  transition: all 0.6s ease-in-out;
}
.light-pink-transparent {
  background-color: transparent !important;
  border-radius: 7px;
  border: 1px groove #f52d7f;
  transition: all 0.6s ease-in-out;
}
.bg-danger {
  background-color: #ee3535 !important;
  border-radius: 7px;
  transition: all 0.6s ease-in-out;
}
.bg-danger:hover {
  background-color: #ee3535aa !important;
  border-radius: 7px;
  transition: all 0.6s ease-in-out;
}
.bg-light-gray {
  background-color: #a8a8a8 !important;
  border-radius: 7px;
  transition: all 0.6s ease-in-out;
}
.bg-light-gray:hover {
  background-color: #a8a8a8aa !important;
  border-radius: 7px;
  transition: all 0.6s ease-in-out;
}

.light-pink-transparent:hover {
  background-color: #f52d7fac !important;
}

.portal {
  padding-left: 260px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #f5f7f9;
}

.san-sherif {
  font-family: sans-serif !important;
}

.bg-white {
  background-color: white !important;
}

.w-max-content {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

/* HEADER STYLING */
header {
  background-color: #1c811c;
  color: #fff;
  width: calc(100% - 260px);
  z-index: 100;
  height: 62px;
  position: fixed;
}
.header-content {
  padding: 10px 20px !important;
}
.search-box {
  width: 260px;
}
.search-box input {
  background-color: #00000000;
  border: none;
  color: #fff;
  padding: 10px;
  width: 100%;
}
.search-box input::-webkit-input-placeholder {
  color: #bcbccb;
}
.search-box input:-ms-input-placeholder {
  color: #bcbccb;
}
.search-box input::placeholder {
  color: #bcbccb;
}
header .notification svg {
  color: #bcbccb;
  width: 23px;
  height: auto;
}
header .notification span.indicator {
  width: 9px;
  height: 9px;
  position: absolute;
  background-color: #ffc06a;
  border: 1px solid #fff;
  border-radius: 50%;
  right: 11px;
  top: 2px;
}
.header-right .right-item {
  border-right: 1px solid #c6c6d68d;
  padding: 5px 10px;
}
.header-right .right-item:last-child {
  border-right: none;
  padding: 5px 0 5px 10px;
}
header .nav-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
header .nav-menu li {
  padding: 0 10px;
  font-size: 0.8rem;
}
.user-avater-details p {
  line-height: 0.9rem;
}
.user-avater-details .name {
  font-size: 0.7rem;
  font-weight: 700;
}
.user-avater-details .role {
  font-size: 0.6rem;
}
.user-avater-details img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.user-avater-details .arrow svg {
  width: 19px;
  height: auto;
  color: #e8a85f;
}
/* END OF HEADER STYLING */

.main-content {
  padding-top: 90px;
  min-height: calc(100vh - 58px);
  height: 100%;
}

/* LOCATION DETAILS */
.location-details p {
  line-height: 0.9rem;
}
.location-details .location {
  font-size: 0.8rem;
  font-weight: 700;
}
.location-details .login-time {
  font-size: 0.7rem;
}
.location-details img {
  width: 21px;
  height: auto;
}
/* END OF LOCATION DETAILS */

/* SEARCH INPUT STYLING */
.search-input {
  border: 1px solid #1c801c;
  padding: 5px 10px;
  background-color: #d5ffd5;
  border-radius: 3px;
  width: 350px;
}

.wow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.search-input input {
  border: none;
  background-color: #00000000;
  padding: 5px;
  width: 100%;
}
/* END OF SEARCH INPUT STYLING */

/* TABLE STYLING */
.table-view {
  display: table;
  table-layout: auto;
  width: 100%;
  border-spacing: 0 10px;
}
.table-view .table-header {
  display: table-header-group;
  table-layout: auto;
  background-color: #d5ffd5;
  font-weight: bold;
}
.table-view .table-body {
  display: table-row-group;
  table-layout: auto;
}
.table-view .table-row {
  display: table-row;
  table-layout: auto;
}
.table-view .table-body .table-row {
  background-color: #fff;
  border-top: 10px solid transparent;
}
.table-view .table-body .table-row:hover,
.table-view .table-body .table-row.active {
  background-color: #d5ffd5;
}
.hover-dash:hover {
  background-color: #d5ffd5;
}
.table-view .table-header .th,
.table-view .table-body .table-row .td {
  display: table-cell;
  padding: 10px;
  vertical-align: middle;
  text-align: left;
}
.circle {
  background-color: green;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
.border {
  height: 50px;
  width: 1px;
  background-color: red;
}
.md {
  display: table-cell;
  padding: 10px;
  vertical-align: middle;
}

.vd {
  display: flex;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  vertical-align: auto;
}

.table-view .table-row .th:last-child button {
  float: right;
}

.table-view .table-row .th.actions,
.table-view .table-row .td.actions {
  text-align: right;
}

button.add-button {
  border: 1px solid #1c811c;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
button.add-button svg {
  font-size: 1.5rem;
  color: #1c811c;
}

.actions button {
  background-color: #1c811c45;
  border: none;
  padding: 8px 8px 4px;
}
.actions button.del {
  background-color: #ff121b45;
}
.actions button svg {
  color: #1c811c;
  width: 20px;
  height: auto;
}

.status {
  position: relative;
  padding-left: 20px;
}
.status::after {
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  background-color: #777;
  content: '';
  top: 50%;
  left: 0;
  transform: translateY(-66%);
}
.status-7::after {
  background-color: green;
}
.status-1::after,
.status-r-1::after {
  background-color: #1efd1e;
}
.status-3::after,
.status-r-3::after {
  background-color: #eb9e11;
}
.status-0::after,
.status-r-0::after {
  background-color: #c43035;
}
/* END OF TABLE STYLING */

/* bordered-table Styling */
#bordered-table th:first-child,
#bordered-table td:first-child {
  border-left: none;
}
#bordered-table th:last-child,
#bordered-table td:last-child {
  border-right: none;
}
#bordered-table th,
#bordered-table td {
  border: none;
  border-left: 0.5px solid #ddd;
  border-right: 0.5px solid #ddd;
  padding: 8px;
  border-spacing: 1px;
}

#bordered-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #d5ffd5;
}

/* End of border Table styling */

/* OVERLAY */
.message-overlay,
.loading-overlay,
.overlay {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #1c811c66;
  position: fixed;
  z-index: 1000;
  padding: 100px 20px;
  overflow: auto;
}
.message-overlay {
  background-color: #fff;
}
svg.close-btn {
  top: 15px;
  right: 15px;
  position: fixed;
  font-size: 5rem;
  color: #fff;
  background-color: #1c811ccc;
  border: 1px solid;
  box-shadow: 0 0 2px black;
  z-index: 1;
  transition: all 0.3s ease-out;
}
svg.close-btn:hover {
  background-color: #c4303566;
}
.loading-overlay {
  z-index: 1001;
}
.loading-inner-box,
.modal-box {
  margin: 0 auto;
  position: unset;
  background-color: #fff;
}
.loading-inner-box {
  padding: 50px 20px;
  width: 250px;
  text-align: center;
}
/* END OF OVERLAY STYLING */

/* FORM INPUTS STYLING */
.form-group {
  position: relative;
}
.form-group label {
  font-size: 0.75rem;
  color: #999;
}
.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  height: 39px;
  padding: 10px 10px 10px 0;
  border: none;
  border-bottom: 1px solid #c6c6c6;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.9rem;
  color: #777;
}
.form-group select::-ms-expand {
  display: none;
}
.form-group textarea {
  height: auto;
  line-height: 1.4rem;
}

.form-group select:disabled,
.form-group input:disabled {
  color: rgb(84, 84, 84);
  cursor: default;
  background-color: rgb(235, 235, 228);
}
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none;
  border-width: 2px;
}
.form-group-search input {
  padding-right: 20px;
}
.form-group-search svg {
  position: absolute;
  right: 0;
  bottom: 13px;
}
input#file {
  display: none;
}
label#file-label {
  cursor: pointer;
}
/* END OF FORM INPUTS STYLING */

/* BUTTON STYLES */
.cancel-btn {
  background-color: #efefef;
  border: 1px solid #bcbcbc;
  padding: 8px 15px;
  color: black;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.btn {
  background-color: #1c811c;
  padding: 8px 15px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.btn:hover {
  background-color: #1c811c88;
}
i.btn {
  font-style: normal;
}
.btn-light {
  background-color: #1c811c1c;
  border: 1px solid#1C811C;
  padding: 7px 15px;
  color: #555;
}
.btn-large {
  padding: 14px;
}
.btn-large.btn-light {
  padding: 13px 14px;
}
.btn-round {
  border-radius: 50px !important;
}
.btn-gray {
  background-color: #838383;
  color: #e8e8e8;
}
.btn-small {
  padding: 5px;
  margin: 2px 0;
}
.btn svg {
  font-size: 1rem;
  color: #1c811c;
}
.btn svg.right {
  margin-left: 10px;
}
.btn svg.left {
  margin-right: 10px;
}
.btn-group {
  border-radius: 10px;
  display: flex;
}
.btn-group button {
  border-radius: 0;
}
.btn-group button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.btn-group button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.btn-del {
  background-color: #de2222;
}
.btn-del:hover {
  background-color: #de2222aa;
}
button:disabled,
button:disabled:hover {
  cursor: default;
  background-color: #999;
}
button:focus {
  outline: none;
}
.btn-loading {
  width: 20px;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 20px;
}

.clear-request-form-btn {
  background-color: green;
  color: #fff;
  font-size: 2.5rem;
  position: absolute;
  top: -2rem;
  right: 0;
}
/* END 0F BUTTON STYLES */

/* FORM WITH TABS STYLING */
.tab-btns {
  border-bottom: 1px solid #e9e9f0;
}
.tab-btns button {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid#E9E9F0;
  border-bottom: none;
  background-color: #f8f8f8;
  color: #43425d;
  padding: 10px;
  margin-right: 5px;
}
.tab-btns button:last-child {
  margin-right: 0;
}
.tab-btns button.active {
  background-color: #fff;
}
.user-full-details .left {
  width: calc(100% - 300px);
}
.user-full-details .left .content {
  background-color: #fff;
  box-shadow: 0px 2px 6px #0000000a;
}
.user-full-details .right {
  width: 300px;
  padding: 20px 10px;
}
.profile-brief img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}
.profile-brief .brief {
  background-color: #fff;
  box-shadow: 0px 2px 6px #0000000a;
  padding: 20px 10px;
  font-family: sans-serif;
}
.profile-brief .brief hr {
  width: calc(100% + 20px);
  margin: 10px -10px;
}
.profile-brief .brief p {
  font-size: 0.8rem;
}
.white-space-nowrap {
  white-space: nowrap !important;
}
.float-right {
  float: right !important;
}

/* Loader style */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border: 2.3px solid #d3d3d3;
  border-radius: 50%;
  border-top: 2.3px solid #017d34dd;
  width: 16px;
  height: 16px; /* Safari */
  animation: spin 2s linear infinite;
}

.loading-overlay {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #1c811c66;
  position: fixed;
  z-index: 1000;
  padding: 100px 20px;
  overflow: auto;
}

.loading-inner-box {
  padding: 50px 20px;
  width: 320px;
  text-align: center;
  box-shadow: 0 0 10px #888;
}
.suggestions::-webkit-scrollbar {
  width: 7px;
}
.suggestions::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.suggestions::-webkit-scrollbar-thumb {
  background: rgb(220, 220, 220);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.suggestions {
  color: rgba(112, 112, 112, 0.8);
  border-left: 0.5px solid #6669;
  border-right: 0.5px solid #6669;
  max-height: 100px;
  overflow: auto;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}

.suggestions > div {
  padding: 6px 10px;
}

.suggestions div.suggestion-item:hover {
  background-color: #868383aa !important;
  /* background-color: red !important; */
  cursor: pointer;
  z-index: 9999;
}
strong {
  font-weight: 900 !important;
}

.react-dropdown-select-input {
  display: none;
}
/* style the accordion section  */
.accordion__section {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px 0;
  box-shadow: 0px 2px 6px #0000000a;
}

/* style the button that are used to open and close the accordion  */
.accordion {
  background-color: #fff;
  color: black;
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: 0px 2px 6px #0000000a;
  transition: all 0.6s ease;
}

/* add background color on the button on hover and if it is active */
.accordion:hover,
.active {
  background-color: #d5ffd5;
  color: black;
  border-bottom: 1px solid #1c811c;
}

/* style the accordion title */
.accordion__title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

/* style accordion icon  */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* style the accordion content */
.accordion__content {
  /* background-color: white; */
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* style the accordion content text */
.accordion__text {
  font-family: 'Open-Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

/**
 * Main wrapper
 */
.select-search {
  width: 100%;
  position: relative;
  font-family: sans-serif;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 5px;
  width: 6px;
  height: 6px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  padding-right: 20px !important;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: auto;
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: auto;
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

